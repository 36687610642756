import { Component } from 'react'
import ReactGA from 'react-ga4';

class Analytics extends Component {
  componentDidMount() {
    if (process.env.REACT_APP_TRACKING_ID && !window.ga) {
      console.log("We are starting up app analytics tracking, with key", process.env.REACT_APP_TRACKING_ID)
      ReactGA.initialize(process.env.REACT_APP_TRACKING_ID)
      window.ga = 'initialized'
  }
}
  
  render() { return null }
}

export default Analytics;